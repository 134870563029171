import React, { Component } from "react";
import { graphql } from "gatsby";
import Head from "../components/head/head";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import CookieConsent from "../components/cookieConsent/cookieConsent";
import { getParams } from '../utils/analytics/UtmReferrers';
import global from '../styles/global.module.css';
import CreatePasswordStep from "../components/registration/RegistrationSteps/Steps/CreatePasswordStep";
import Cookies from 'js-cookie';
import { renderContentLocale } from '../utils/ContentHelper';
const queryString = require('querystring');
require('../styles/global.css');

const axios = require('axios');

class CreatePasswordPage extends Component {

  constructor(props) {
    super(props);

    const userData = props.location.search.replace('?', '');
    const user = queryString.parse(userData);

    if (user.user) {
      const tokenPieces = user.user.split(' q');
      var token = tokenPieces.join('-');
      this.isVerified(user, userData, token);
    } else {
      this.isVerified(user, userData);
    }

    Cookies.set('microsite_registered_user', true, { domain: '.c2fo.com' });

    this.state = {
      lang: user.lang ? user.lang : ''
    }
  }


  componentDidUpdate() {
    if (this.state.authorized) {
      this.triggerAnalytics();
    }
  }

  triggerAnalytics() {
    //triggers direct call to adobe when the page loads due to a user's auth service uuid being valid
    var _satellite = window._satellite

    if (_satellite) {
      _satellite.track("regMid");
    }
  }

  handler(field, value) {
    this.setState({
      [field]: value
    })
  }

  isVerified = (user, userData, token) => {
    axios.post('/.netlify/functions/validateUser', { userData })
      .then(res => {
        if (res.data === 'success') {
          this.setState({
            authorized: true
          })
        } else if (res.data.email) {
          this.setState({
            emailAddress: res.data.email,
            lang: res.data.locale ? res.data.locale : 'en',
            authorized: true,
            token: user.auth_service_uuid
          })
        } else if (res.data === 'emailVerified') {
          axios.post('/.netlify/functions/userLookup', {
            email: user.emailAddress
          }).then(res => {
            if (res) {
              this.setState({
                emailUserData: res.data,
                emailAddress: user.emailAddress,
                token: token
              })
            }
          })
          this.setState({
            authorized: true,
            fromEmail: true
          })
        } else {
          if(typeof window !== 'undefined') {
            window.location.href = '/';
            this.setState({
              authorized: false
            })
        }}
      })
      .catch(err => {
        if(typeof window !== 'undefined') {
          window.location.href = '/';
          this.setState({
            authorized: false
          })
        }
      })
    }

  onSubmit = fields => {
    this.setState({ registering: true, fields: fields }, () => {
      this.createLead();
    });
  }


  render() {
    let domain;
    const digitalDataTitle = "create-password";

    if (typeof window !== 'undefined') {
      /**
       * pulls domain buyer name
       */
      domain = window.location.href;

      //passes domain to getParams util to set UTM parameters for Google Analytics to pick up
      getParams(domain);
    }
    const nodes = this.props.data.allBuyerList.edges[0].node;
    const relationshipData = nodes.relationship;
    const defaultContent = nodes.content[0];
    let buyerData = nodes.buyer;
    let localeContent;
    var divisionUuid = relationshipData[0].divisionUuid;
    var thankYouContent = nodes.thankYouContent[0];
    var localeForm = nodes.formContent[0];
    var langCode = this.state.lang;


    localeContent = nodes.content.find(
      content => content.contentLocale === langCode
    );

    if (!localeContent) {
      localeContent = nodes.content.find(
        content => content.contentLocale.split('-')[0] === langCode
      );
    }

    localeForm = nodes.formContent.find(
      formContent => formContent.contentLocale === langCode
    );

    if (!localeForm) {
      localeForm = nodes.formContent.find(
        formContent => formContent.contentLocale.split('-')[0] === langCode.split('-')[0]
      );
    }

    thankYouContent = nodes.thankYouContent.find(
      content => content.contentLocale === langCode
    )

    if (!thankYouContent) {
      thankYouContent = nodes.thankYouContent.find(
        content => content.contentLocale.split('-')[0] === langCode.split('-')[0]
      );
    }

    const renderContent = renderContentLocale(localeContent, defaultContent);

    return (
      <div id="buyer-microsite">
        <Head
          buyer={buyerData}
          locale={this.state.lang}
          digitalDataTitle={digitalDataTitle} />
        <Header
          handler={this.handler.bind(this)}
          buyer={buyerData}
          localeContent={relationshipData}
          phone=""
          content={renderContent}
          params={this.props.location}
          locale={this.state.lang}
          hideLogin={true} />
        <div className={global.c2foContainer__registrationApp}>
          <div className={global.c2foApp}>

            <div className={`${global.c2foFlex} ${global.c2foRegistration__container}`}>
              {this.state.authorized ?
                <CreatePasswordStep
                  buyer={buyerData}
                  division={divisionUuid}
                  form={localeForm ? localeForm : nodes.formContent[0]}
                  content={renderContent}
                  thankYouContent={thankYouContent ? thankYouContent : nodes.thankYouContent[0]}
                  params={this.props.location}
                  emailBypass={this.state.fromEmail}
                  emailAddress={this.state.emailAddress}
                  userToken={this.state.token}
                  userData={this.state.emailUserData} /> :
                ''
              }
            </div>
          </div>
        </div>
        <Footer
          shortPageClass={true}
          buyerInfo={buyerData}
          content={renderContent}
        />
        <CookieConsent content={renderContent} />
      </div>
    );
  }
}

export default CreatePasswordPage;

export const query = graphql`
query($permalink: String ) {
  allBuyerList(filter: {buyer: {permalink: {eq: $permalink}}}) {
    edges {
      node {
        ...Buyer
        ...Relationship
        ...Content
        ...Form
        ...ThankYou
      }
    }
  }
}
`
